/* Styles go here */
.id {
  border-radius: 50%;
}

.network {
  width: 125px;
  height: 43px;
  color: #FFFFFF;
  background-color: #303030;
  border: 1mm ridge #55FF55;
}

.balance {
  width: 125px;
  height: 43px;
  color: #FFFFFF;
  background-color: #505050;
  border: 1mm ridge #55FF55;
}

.account {
  width: 150px;
  height: 43px;
  color: #55FF55;
  background-color: #707070;
  border: 1mm ridge #55FF55;
}

.bgDark7 {
  background-color: #1D1D1D;
}

.navBorderBottom{
  border-bottom: 1mm ridge rgba(85, 255, 85, .8)
}

.loading{
  height: 1440px;
  background-color: #1D1D1D;
}

p {
  margin: 5px
}

.detailsGameTitle {
  text-align: left;
}

.detailsDeveloperContainer {
  text-align: left;
  width: 100%; 
}

.tgfMain {
  color: #55FF55;
  border-color: #55FF55;
}

.tgfMain:disabled {
  color: #8B8B8B;
  border-color: #8B8B8B;
}

.disabledColor {
  color: #8B8B8B;
}

.belowThumbnail {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.Details .buttonEnclosure {
  text-align: center;
}

.downloadButtonEnclosure {
  margin: 5px;
}

.stockProgressText {
  color: #1D1D1D;
}

.discountRatio {
  color: #ff0000;
  background-color: #ff8a8a;
  width: fit-content;
}

.thumbnail {
  width: 200px;
  height: 300px;
  border: 1mm ridge;
}

.price {
  color: #ff0000;
  background-color: transparent;
}

.discountedPrice {
  color: #ff0000;
}

.normalPrice {
  color: #ff0000;
}

.normalPriceSlashed {
  color: #8B8B8B;
  text-decoration: line-through;
}

.detailsPriceContainer .discountedPrice {
  float: left;
}

.detailsPriceContainer .normalPrice {
  float: left;
}

.detailsPriceContainer .normalPriceSlashed {
  float: left;
  margin-left: 10px;
}

.detailsPriceContainer .discountRatio {
  clear: both;
  display:block;
  margin-left: 0px;
}

.item {
  width: 250px;
  text-align: center;
}

.item .priceLeft {
  float: left;
}

.item .priceRight {
  float: right;
}

.item .price {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.item .afterPrice {
  clear: both;
  display:block;
}

.description {
  margin-top: 8px;
}

.unlockWarning {
  color: rgb(255, 89, 89);
}

.discountWarning {
  color: rgb(255, 89, 89);
}

.discountWarningCountDown {
  color: rgb(255, 89, 89);
}